<template>
  <div>
    <div
      class="
        consume-bar
        margin-top-bottom-twenty
        box-shadow-light-grey
        border-radius-6
        padding-right-left-5
      "
    >
      <el-menu
        :default-active="activeSubMenu"
        active-text-color="#00bf8a"
        mode="horizontal"
        class="border-radius-6"
        router
      >
        <el-menu-item
          class="font-eighteen margin-right-23"
          index="/statistics/channelData/intervalUser"
          @click.native="handleTabClick('/statistics/channelData/intervalUser')"
          >区间用户消耗</el-menu-item
        >
        <el-menu-item
          class="font-eighteen margin-right-23"
          index="/statistics/channelData/intervalChannel"
          @click.native="
            handleTabClick('/statistics/channelData/intervalChannel')
          "
          >区间公众号消耗</el-menu-item
        >
        <el-menu-item
          class="font-eighteen margin-right-23"
          index="/statistics/channelData/intervalBook"
          @click.native="handleTabClick('/statistics/channelData/intervalBook')"
          >区间书籍消耗</el-menu-item
        >
        <el-menu-item
          class="font-eighteen margin-right-23"
          index="/statistics/channelData/testIntervalBook"
          @click.native="
            handleTabClick('/statistics/channelData/testIntervalBook')
          "
          >测试书籍消耗</el-menu-item
        >
        <el-menu-item
          class="font-eighteen margin-right-23"
          index="/statistics/channelData/intervalPutAnalysis"
          @click.native="
            handleTabClick('/statistics/channelData/intervalPutAnalysis')
          "
          >区间投放分析</el-menu-item
        >
      </el-menu>
    </div>
    <div class="consume-part">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  name: "channel",
  data() {
    return {
      tabName: this.$route.path,
      activeSubMenu: this.$route.path,
    };
  },
  watch: {
    $route(newVal) {
      this.activeSubMenu = newVal.path;
      this.tabName = newVal.path;
    },
  },
  methods: {
    handleTabClick(tab) {
      this.tabName = tab;
    },
  },
};
</script>

<style lang="scss" scoped>
// /deep/ .el-menu--horizontal > .el-menu-item.is-active{
//   border-right:none !important;
//   background-color:#fff !important;
//   border-bottom: 2px solid #00bf8a !important;
// }
.consume-bar {
  background: #fff;
  /deep/ .el-menu-item,
  .el-submenu__title {
    height: 34px;
  }
}
.el-menu--horizontal > .el-menu-item {
  height: 52px;
  line-height: 52px;
}
.consume-part {
  box-shadow: 0px 0px 6px 0px #f1f4f6;
}
</style>
